import { Grid } from "@mui/material";
import VideoCardNew from "components/Cards/VideoCardNew";
import SkeletonVideoCard from "components/Skeleton";

const Videos = ({ items, loading, currentPage }) => {
  const dummyData = new Array(12).fill(null);
  return (
    <Grid container spacing={3} px={{ xs: 0, sm: 5 }} py={3}>
      {loading ? (
        dummyData.map((_, index) => (
          <Grid key={index} item xs={12} md={6} lg={3}>
            <SkeletonVideoCard />
          </Grid>
        ))
      ) : items.length === 0 ? (
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          No Records Found
        </Grid>
      ) : (
        items.map((item) => (
          <Grid key={item.id} item xs={12} md={6} lg={3}>
            <VideoCardNew
              current_page={currentPage}
              video={item.video_url}
              video_key={item.video_key}
              lesson={item.lesson_name}
              title={item.title}
              description={item.description}
              lesson_title={item.lesson.title}
              category_name={item?.lesson?.category?.name}
              video_upload_date={item.video_upload_date}
              id={item.id}
              video_status={item.video_status}
              video_thumbnail={item?.video_thumbnail}
            />
          </Grid>
        ))
      )}
    </Grid>
  );
};

export default Videos;
