import Cookies from "js-cookie";
import axios from "../util/axios";
// import toastMessage from "../util/toast";
export const postLogin = async (data) => {
  try {
    const response = await axios.post("/auth/local", {
      ...data,
      info: { role: "Admin" },
    });
    if (response?.data) {
      const expirationDate = new Date();
      expirationDate.setMonth(expirationDate.getMonth() + 2);
      Cookies.set("token", response?.data?.data?.token, {
        expires: expirationDate,
      });
      Cookies.set("refreshToken", response?.data?.data?.refresh_token, {
        expires: expirationDate,
      });
      Cookies.set("email", response?.data?.data?.user?.email, {
        expires: expirationDate,
      });
      Cookies.set("sessionId", response?.data?.data?.refresh_token, {
        expires: expirationDate,
      });
      return { message: response?.data?.message };
    }
  } catch (error) {
    if (!navigator.onLine) {
      throw new Error("Slow or no internet connection");
    } else if (error?.response?.data?.statusCode === 401) {
      throw new Error(error?.response?.data?.message);
    } else {
      throw new Error(
        error?.response?.data?.message ||
          error?.message ||
          "something went wrong"
      );
    }
  }
};
export const logout = async () => {
  try {
    const session_id = Cookies.get("sessionId");
    const response = await axios.post("/auth/logout", { session_id });
    if (response) {
      Cookies.remove("sessionId");
      Cookies.remove("refreshToken");
      Cookies.remove("token");
      Cookies.remove("email");
      return response;
    }
  } catch (error) {
    Cookies.remove("sessionId");
    Cookies.remove("refreshToken");
    Cookies.remove("token");
    Cookies.remove("email");
  }
};

export const putPassword = async (data) => {
  try {
    const response = await axios.put("/user/password", data);
    if (response?.status === 200) {
      Cookies.remove("sessionId");
      Cookies.remove("refreshToken");
      Cookies.remove("token");
      Cookies.remove("email");
    }
    return response?.status;
  } catch (error) {
    if (!navigator.onLine) {
      throw new Error("Slow or no internet connection");
    } else {
      throw new Error(
        error?.response?.data?.message ||
          error?.message ||
          "something went wrong"
      );
    }
  }
};
