import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PropTypes from "prop-types";
import { io } from "socket.io-client";

import {
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import MDButton from "components/MDButton";
import {
  setNotification,
  setPlaying,
  setTotalItems,
  setVideo,
  setVideos,
  useAdminPanelController,
} from "context";
import VideoPlayer from "pages/chapters/AddEditVideo/player";
import EditVideo from "pages/chapters/EditVideo";
import { useEffect, useState } from "react";
import { deleteVideo, getAllVideos } from "services/video";
import { convertDate } from "util/date";
import styles from "./videocard.module.scss";

function VideoCard({
  video,
  title,
  description,
  category_name,
  lesson_title,
  video_upload_date,
  id,
  video_key,
  lesson,
  video_status,
  current_page,
  video_thumbnail,
}) {
  const [controller, dispatch] = useAdminPanelController();
  const [onMouse, setOnMouse] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [show, setShow] = useState(false);
  const [menu, setMenu] = useState(null);
  const [status, setStatus] = useState([]);
  const [loading, setLoading] = useState(false);
  const closeMenu = () => setMenu(null);
  useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKET_URL);
    socket.on("message", async () => {
      if (loading) return;
      if (video_status === "PROCESSING") {
        setStatus(["PROCESSING"]);
        setLoading(true);
        try {
          const response = await getAllVideos(
            controller?.lesson,
            controller?.category,
            controller?.search,
            current_page
          );
          setVideos(dispatch, response?.videos);
          setTotalItems(dispatch, response?.count || null);
          if (response) setLoading(false);
        } catch (error) {}
      }
    });
    return () => {
      socket.off("message");
      socket.disconnect();
    };
  }, []);
  const handleCloseShow = () => {
    setShow(false);
  };
  const handleDelete = async () => {
    setShow(false);
    setMenu(null);
    const { message } = await deleteVideo(id);
    if (message) {
      const response = await getAllVideos(
        controller?.lesson,
        controller?.category,
        controller.search,
        controller?.currentPage
      );
      setTotalItems(dispatch, response?.count || null);
      setVideos(dispatch, response?.videos);
      setNotification(dispatch, {
        color: "success",
        icon: "check",
        title: "Success",
        content: "Video removed successfully",
        open: true,
      });
    }
  };

  const handleOpen = () => {
    setShow(true);
    closeMenu();
  };
  const handleEditModal = () => {
    setPlaying(dispatch, null);
    setEditModal(true);
    setMenu(null);
    setVideo(dispatch, {
      video_key,
      title,
      description,
      lesson,
      video_url: video,
      id,
      category_name,
    });
  };
  const openMenu = ({ currentTarget }) => setMenu(currentTarget);

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      PaperProps={{
        style: {
          width: "8rem",
        },
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem
        onClick={handleEditModal}
        // sx={{
        //   display: "flex",
        //   justifyContent: "space-between",
        // }}
      >
        <Icon sx={{ marginRight: "1.7rem" }}>edit</Icon>{" "}
        <span style={{ marginRight: "1.7rem" }}> Edit</span>
      </MenuItem>
      <MenuItem
        onClick={handleOpen}
        // sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Icon sx={{ marginRight: "1.7rem" }}>delete</Icon> <span>Remove</span>
      </MenuItem>
    </Menu>
  );

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "transparent",
        overflow: "visible",
        background: "#fff",
        boxShadow: "none",
        height: "26rem",
      }}
      className={styles.card}
      onMouseEnter={() => setOnMouse(true)}
      onMouseLeave={() => setOnMouse(false)}
    >
      <Box className={styles.media_back} sx={{ height: "auto" }}>
        <VideoPlayer
          videoUrl={video_status === "PROCESSING" ? "" : video}
          id={id}
          video_thumbnail={video_thumbnail}
        />
      </Box>

      <MDBox
        position="relative"
        width="100.25%"
        shadow="xl"
        borderRadius="xl"
      ></MDBox>
      <MDBox mt={1} className={styles.content}>
        <MDBox className={styles.content_border} />
        <MDBox>
          <MDBox
            display="flex"
            sx={{
              alignItems: "center",
              justifyContent: "space-between",
            }}
            className={styles.content_header}
          >
            <MDBox
              display="flex"
              gap={1}
              sx={{
                alignItems: "center",
              }}
            >
              <Icon
                sx={{
                  opacity: 0.7,
                }}
              >
                access_time
              </Icon>
              <MDTypography variant="button" fontWeight="regular" color="text">
                {convertDate(video_upload_date)}{" "}
                {video_status === "PROCESSING" && (
                  <Chip label="Processing..." color="error" size="small" />
                )}
                {video_status === "COMPLETED" && status.length !== 0 && (
                  <Chip
                    label="Completed"
                    size="small"
                    style={{ backgroundColor: "#65B741", color: "#fff" }}
                    className={styles.show_for_5s}
                  />
                )}
              </MDTypography>
            </MDBox>
            <MDBox
              display="flex"
              sx={{
                alignItems: "center",
              }}
              spacing={1}
            >
              <IconButton size="small" onClick={openMenu}>
                <Icon>more_vert</Icon>
              </IconButton>
            </MDBox>
          </MDBox>
          <MDBox mb={1}>
            <MDTypography
              component="a"
              target="_blank"
              rel="noreferrer"
              variant="h5"
              className={styles.card_title}
            >
              {title}
            </MDTypography>
          </MDBox>
          <MDBox mb={1} mt={1} lineHeight={0} display="flex" gap={1}>
            <Icon
              sx={{
                opacity: 0.7,
              }}
            >
              local_library
            </Icon>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="button">{lesson} : </Typography>
              <MDTypography variant="button" fontWeight="light" color="text">
                {lesson_title}
              </MDTypography>
            </Box>
          </MDBox>
          <MDBox mb={3} lineHeight={0} display="flex" gap={1}>
            <Icon
              sx={{
                opacity: 0.7,
              }}
            >
              event_note
            </Icon>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="button">category: </Typography>
              <MDTypography variant="button" fontWeight="light" color="text">
                {category_name}
              </MDTypography>
            </Box>
          </MDBox>
        </MDBox>
      </MDBox>
      <Dialog
        open={show}
        onClose={handleCloseShow}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Remove video</DialogTitle>
        <DialogContent>
          Are you sure you want to remove this video?
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleCloseShow} color="secondary">
            Cancel
          </MDButton>
          <MDButton color="primary" onClick={handleDelete}>
            Remove
          </MDButton>
        </DialogActions>
      </Dialog>
      {editModal && (
        <EditVideo
          closeHandler={() => {
            setEditModal(false);
          }}
          id={id}
        />
      )}
      {renderMenu}
    </Card>
  );
}

VideoCard.propTypes = {
  video: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default VideoCard;
