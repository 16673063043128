// @mui icons

import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import Navbar from "components/Navbar";
import { setNotification, useAdminPanelController } from "context";
import Cookies from "js-cookie";
import ContentLayout from "layouts/content";
import throttle from "lodash.throttle";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import OTPInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import {
  amountSchema,
  emailShcema,
  optSchema,
  passwordSchema,
} from "schema/settingsForm";
import { logout, putPassword } from "services/auth";
import {
  getOtpToEmail,
  getSettingsInfo,
  postOtp,
  putSubscritpion,
  resendOtp,
  updateNewEmail,
} from "services/settings";
function Settings() {
  const [show, setShow] = useState(null);
  const [newEmail, setNewEmail] = useState("");
  const [sessionId, setSessionId] = useState("");
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  const email = Cookies.get("email");
  const [controller, dispatch] = useAdminPanelController();
  const schema =
    show === 1
      ? passwordSchema
      : show === 2
      ? amountSchema
      : show === 3
      ? emailShcema
      : optSchema;
  const {
    register,
    handleSubmit,
    trigger,
    setError,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });
  const handlePasswordChange = async (data) => {
    setLoading(true);
    try {
      const { newPassword, currentPassword } = data;
      const response = await putPassword({
        password: newPassword,
        old_password: currentPassword,
      });
      if (response) {
        reset();

        setShow(null);
        setNotification(dispatch, {
          color: "success",
          icon: "check",
          title: "Success",
          content: "Password changed successfully",
          open: true,
        });
        navigate("/sign-in", { replace: true });
      }
    } catch (error) {
      setNotification(dispatch, {
        color: "error",
        icon: "error",
        title: "Error",
        content: error.message,
        open: true,
      });
    }
    setLoading(false);
  };
  const handleSubscription = async ({ amount }) => {
    setLoading(true);
    try {
      const response = await putSubscritpion(amount);
      if (response) {
        setData(response);
        reset();
        setShow(null);
        setNotification(dispatch, {
          color: "success",
          icon: "check",
          title: "Success",
          content: "Amount changed Successfully",
          open: true,
        });
      }
    } catch (error) {
      setNotification(dispatch, {
        color: "error",
        icon: "error",
        title: "Error",
        content: error.message,
        open: true,
      });
    }
    setLoading(false);
  };

  const handleEmail = async ({ email }) => {
    setLoading(true);
    try {
      setNewEmail(email);
      const response = await getOtpToEmail(email);
      if (response) {
        setSessionId(response);
        reset();
        setShow(4);
      }
    } catch (error) {
      setNotification(dispatch, {
        color: "error",
        icon: "error",
        title: "Error",
        content: error.message,
        open: true,
      });
    }
    setLoading(false);
  };

  //otp
  const handleOtp = (e) => {
    if (e?.length === 6) {
      setError("otp", {
        type: "manual",
        message: "",
      });
    }
    setOtp(e);
  };

  const handleResend = throttle(async () => {
    try {
      const respone = await resendOtp(sessionId);
      if (respone) {
        setNotification(dispatch, {
          color: "success",
          icon: "check",
          title: "Success",
          content: "Code send successfully",
          open: true,
        });
      }
    } catch (error) {
      if (error.message === "Session expired") {
        setShow(3);
      }
      setNotification(dispatch, {
        color: "error",
        icon: "error",
        title: "Error",
        content: error.message,
        open: true,
      });
    }
  }, 600);

  const verifyOtp = async () => {
    try {
      setLoading(true);
      if (otp.length !== 6) {
        setError("otp", {
          type: "manual",
          message: "Please enter the code",
        });
        setLoading(false);
        return;
      }
      const response = await postOtp(otp, sessionId);
      if (response === 200) {
        const data = await updateNewEmail(newEmail, sessionId);
        if (data === 200) {
          const response = await logout();
          setNotification(dispatch, {
            color: "success",
            icon: "check",
            title: "Success",
            content: "Email changed successfully, Please login again",
            open: true,
          });
          navigate("/sign-in", { replace: true });
          setShow(null);
          setOtp("");
          setLoading(false);
        }
      }
    } catch (error) {
      setNotification(dispatch, {
        color: "error",
        icon: "error",
        title: "Error",
        content: error.message,
        open: true,
      });
      setLoading(false);
    }
  };
  const handleClose = () => {
    setShow(null);
    setOtp("");
    reset();
    setSessionId("");
    setNewEmail("");
    setLoading(false);
    setError("otp", {
      type: "manual",
      message: "",
    });
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getSettingsInfo();
        setData(response);
      } catch (error) {}
    };
    fetchData();
  }, []);
  return (
    <ContentLayout>
      <Navbar />
      <MDBox pt={6} pb={3}>
        {" "}
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <Grid
                container
                justifyContent={"space-between"}
                alignItems={"center"}
                gap={2}
              >
                <Grid item xs={12} md={4}>
                  {" "}
                  <MDTypography variant="h6" color="white">
                    Account Settings
                  </MDTypography>
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
          <Grid item xs={12}>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="white"
              borderRadius="lg"
              coloredShadow="dark"
            >
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} md={6} lg={4} sx={{ ml: "auto" }}></Grid>
              </Grid>

              <Grid
                container
                spacing={3}
                alignItems="center"
                justifyContent="space-between"
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    paddingTop: "2rem",
                    paddingX: "3rem",
                  }}
                >
                  <MDTypography variant="h6" textTransform="capitalize">
                    {" "}
                    business email
                  </MDTypography>
                  <MDTypography
                    variant="button"
                    color="info"
                    fontWeight="medium"
                  >
                    {email}
                  </MDTypography>
                </Box>
                <Grid item xs={12} lg={2}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    sx={{
                      borderRadius: "25px",
                    }}
                    onClick={() => setShow(3)}
                  >
                    Change
                  </MDButton>
                </Grid>
              </Grid>
              <Divider />
              <Grid
                container
                spacing={3}
                alignItems="center"
                justifyContent="space-between"
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "1rem",
                    paddingX: "3rem",
                  }}
                >
                  <MDTypography variant="h6" textTransform="capitalize">
                    {" "}
                    change password
                  </MDTypography>
                  <MDTypography
                    variant="button"
                    color="text"
                    fontWeight="regular"
                  >
                    You can change your account password
                  </MDTypography>
                </Box>
                <Grid item xs={12} lg={2}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    sx={{
                      borderRadius: "25px",
                    }}
                    onClick={() => setShow(1)}
                  >
                    Change
                  </MDButton>
                </Grid>
              </Grid>
              <Divider />
              <Grid
                container
                spacing={3}
                alignItems="center"
                justifyContent="space-between"
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "1rem",
                    paddingX: "3rem",
                  }}
                >
                  <MDTypography variant="h6" textTransform="capitalize">
                    {" "}
                    subscription amount
                  </MDTypography>
                  <MDTypography
                    variant="button"
                    color="info"
                    fontWeight="medium"
                  >
                    {data?.value ? `$${data?.value}` : ""}
                  </MDTypography>
                </Box>
                {/* <Grid item xs={12} lg={2}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    sx={{
                      borderRadius: "25px",
                    }}
                    onClick={() => setShow(2)}
                  >
                    Change
                  </MDButton>
                </Grid> */}
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      {show && (
        <Dialog
          open={show}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <form
            onSubmit={
              show === 1
                ? handleSubmit(handlePasswordChange)
                : show === 2
                ? handleSubmit(handleSubscription)
                : handleSubmit(handleEmail)
            }
          >
            <DialogTitle id="alert-dialog-title" sx={{ textAlign: "center" }}>
              {show === 1
                ? "Change Password"
                : show === 2
                ? "Change Subscription Amount"
                : "Change New Email"}
            </DialogTitle>
            <DialogContent
              maxWidth="md"
              sx={{
                minWidth: { xs: 300, sm: 400, md: 576 },
                paddingBottom: 0,
              }}
            >
              <Grid item xs={12} lg={6}>
                <MDBox
                  display="grid"
                  p={{
                    xs: 1,
                    lg: 3,
                  }}
                  gap={2}
                >
                  {show === 3 && (
                    <>
                      <TextField
                        label="Email"
                        fullWidth
                        InputLabelProps={{
                          style: {
                            paddingTop: "0.2rem",
                            paddingBottom: "0.6rem",
                          },
                        }}
                        {...register("email")}
                        helperText={errors.email?.message || ""}
                        error={Boolean(errors?.email)}
                      />
                    </>
                  )}
                  {show === 4 && (
                    <>
                      <Typography
                        variant="h6"
                        sx={{ paddingBottom: "1rem", textAlign: "center" }}
                      >
                        {" "}
                        We have sent you a six digit verification code to your
                        new email address{" "}
                        <span style={{ color: "#368EEE" }}>{newEmail}</span>
                      </Typography>
                      <OTPInput
                        value={otp}
                        onChange={handleOtp}
                        numInputs={6}
                        renderInput={(props) => <input {...props} />}
                        containerStyle="otpContainer"
                        inputStyle="otpInput"
                        inputType="tel"
                        skipDefaultStyles={true}
                      />

                      <Typography
                        variant="caption"
                        sx={{
                          textAlign: "center",
                          color: "red",
                        }}
                      >
                        {errors?.otp?.message}
                      </Typography>
                    </>
                  )}

                  {show === 1 && (
                    <>
                      <TextField
                        label="Current password"
                        fullWidth
                        InputLabelProps={{
                          style: {
                            paddingTop: "0.2rem",
                            paddingBottom: "0.6rem",
                          },
                        }}
                        {...register("currentPassword")}
                        helperText={errors.currentPassword?.message || ""}
                        error={Boolean(errors?.currentPassword)}
                        onBlur={() => trigger("currentPassword")}
                      />
                      <TextField
                        label="New password"
                        fullWidth
                        InputLabelProps={{
                          style: {
                            paddingTop: "0.2rem",
                            paddingBottom: "0.6rem",
                          },
                        }}
                        {...register("newPassword")}
                        helperText={errors.newPassword?.message || ""}
                        error={Boolean(errors?.newPassword)}
                        onBlur={() => trigger("newPassword")}
                      />{" "}
                      <TextField
                        label="Confirm password"
                        fullWidth
                        InputLabelProps={{
                          style: {
                            paddingTop: "0.2rem",
                            paddingBottom: "0.6rem",
                          },
                        }}
                        {...register("confirmPassword")}
                        helperText={errors.confirmPassword?.message || ""}
                        error={Boolean(errors?.confirmPassword)}
                        onBlur={() => trigger("confirmPassword")}
                      />
                    </>
                  )}
                  {show === 2 && (
                    <>
                      <FormControl fullWidth sx={{ m: 1 }}>
                        <InputLabel htmlFor="outlined-adornment-amount">
                          Amount
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-amount"
                          startAdornment={
                            <InputAdornment position="start">$</InputAdornment>
                          }
                          {...register("amount")}
                          defaultValue={data?.value ? data?.value : ""}
                          error={Boolean(errors?.amount)}
                          onBlur={() => trigger("amount")}
                          label="Amount"
                        />
                        <FormHelperText
                          sx={{ color: "red" }}
                          id="outlined-weight-helper-text"
                        >
                          {errors.amount?.message || ""}
                        </FormHelperText>
                      </FormControl>
                    </>
                  )}
                </MDBox>
              </Grid>
            </DialogContent>
            <DialogActions>
              {show !== 4 ? (
                <>
                  <MDBox mt={3} mb={1}>
                    <MDButton
                      variant="gradient"
                      color="secondary"
                      fullWidth
                      onClick={handleClose}
                    >
                      Cancel
                    </MDButton>
                  </MDBox>
                  <MDBox mt={3} mb={1}>
                    <MDButton
                      variant="gradient"
                      color="info"
                      fullWidth
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? "Changing..." : "Change"}
                    </MDButton>
                  </MDBox>
                </>
              ) : (
                <>
                  <Box
                    sx={{
                      mx: "auto",
                      width: "15rem",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <MDButton
                      variant="gradient"
                      color="info"
                      fullWidth
                      disabled={loading}
                      onClick={verifyOtp}
                    >
                      {loading ? "verifying..." : "  Verify"}
                    </MDButton>
                    <Box
                      sx={{
                        display: "flex",
                        paddingY: "1rem",
                        marginX: "auto",
                      }}
                    >
                      <Typography variant="body2">
                        Didn&apos;t receive code?
                      </Typography>
                      <Typography
                        variant="h6"
                        onClick={handleResend}
                        sx={{
                          color: "#368EEE",
                          marginX: "0.5rem",
                          textDecoration: "underLine",
                          cursor: "pointer",
                        }}
                      >
                        Resend
                      </Typography>
                    </Box>
                  </Box>
                </>
              )}
            </DialogActions>
          </form>
        </Dialog>
      )}
    </ContentLayout>
  );
}

export default Settings;
