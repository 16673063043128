import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DashboardNavbar from "components/Navbar";
import DataTable from "components/Tables/DataTable";

// Data
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import ContentLayout from "layouts/content";
import authorsTableData from "./data/authorsTableData";

import Loading from "components/Loading";
import MDButton from "components/MDButton";
import { useEffect, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import Pagination from "components/Pagination";
import {
  setLessons,
  setNotification,
  setTotalItems,
  useAdminPanelController,
} from "context";
import debounce from "lodash.debounce";
import { Controller, useForm } from "react-hook-form";
import { lessonEditSchema, lessonFormSchema } from "schema/lessonForm";
import { getCategories } from "services/category";
import { getLessons, putLesson } from "services/lessons";
import { getLessons as getAllLessons } from "services/video";
import styles from "./styles.module.scss";
function Lessons() {
  const [controller, dispatch] = useAdminPanelController();
  const [search, setSearch] = useState("");
  const [categories, setCategories] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [allLessons, setAllLessons] = useState([]);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [id, setId] = useState(null); //changed as an object  eg:{id:3,name: lesson 3}
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState(null);
  const [selectCategory, setSelectedCategory] = useState("");
  const schema = show ? lessonFormSchema : lessonEditSchema;
  const {
    register,
    handleSubmit,
    control,
    trigger,
    setError,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });
  const handleClose = () => {
    setShow(false);
    setOpen(false);
    reset();
  };
  const handlTitle = async (data) => {
    setIsSubmiting(true);
    try {
      let response;
      if (data?.lesson) {
        const currentLesson = allLessons.find(
          (item) => item?.name === data?.lesson
        );
        if (currentLesson?.title) {
          setError("lesson", {
            type: "manual",
            message: "Title already exist in this lesson",
          });
          setIsSubmiting(false);
          return;
        }
        response = await putLesson(
          data?.title,
          currentLesson?.id,
          data?.category
        );
      } else {
        response = await putLesson(data?.title, id?.id, data?.category);
      }

      if (response === 200) {
        const data = await getLessons(search, currentPage);
        setLessons(dispatch, data?.lessons);
        setNotification(dispatch, {
          color: "success",
          icon: "check",
          title: "Success",
          content: show
            ? "Lesson added successfully"
            : "Lesson edited successfully",
          open: true,
        });
      }
    } catch (error) {
      setNotification(dispatch, {
        color: "error",
        icon: "error",
        title: "Error",
        content: error.message,
        open: true,
      });
    }
    setIsSubmiting(false);
    handleClose();
  };

  const perPageContent = 10;
  const handleSearch = debounce((e) => {
    setSearch(e?.target?.value.trim());
    setCurrentPage(1);
  }, 400);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getAllLessons("");
        setAllLessons(data);
      } catch (error) {}
    };
    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoryData = await getCategories();
        setCategories(categoryData);
      } catch (error) {}
    };
    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getLessons(search, currentPage, selectCategory);
        setLessons(dispatch, data?.lessons);

        setTotalItems(dispatch, data?.count || null);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    fetchData();
  }, [currentPage, search, selectCategory]);
  const { columns, rows } = authorsTableData(
    setOpen,
    controller?.lessons,
    setId,
    setTitle,
    setCategory,
    currentPage
  );
  return (
    <ContentLayout>
      <DashboardNavbar />
      {loading ? (
        <Loading />
      ) : (
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <Grid
                    container
                    justifyContent={"space-between"}
                    // alignItems={"center"}
                    gap={2}
                  >
                    <Grid item xs={12} md={3}>
                      {" "}
                      <MDTypography variant="h6" color="white">
                        Lessons
                      </MDTypography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={3}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <FormControl fullWidth>
                        <Select
                          value={selectCategory}
                          onChange={(event) => {
                            setSelectedCategory(event?.target?.value);
                            setCurrentPage(1);
                          }}
                          sx={{
                            padding: "12px",
                            color: "#fff !important",
                          }}
                          displayEmpty
                          inputProps={{
                            "aria-label": "Without label",
                          }}
                          classes={{
                            select: styles.selected,
                          }}
                        >
                          <MenuItem className="chapter-select" value="">
                            {" "}
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Icon
                                className={styles.select_icon}
                                sx={{ marginRight: "10px" }}
                              >
                                style
                              </Icon>
                              <span> All Categories</span>
                            </Box>
                          </MenuItem>
                          {categories.map((category) => (
                            <MenuItem
                              key={category.id}
                              className="chapter-select"
                              value={category.id}
                            >
                              {" "}
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Icon
                                  className={styles.select_icon}
                                  sx={{ marginRight: "10px" }}
                                >
                                  style
                                </Icon>
                                <span> {category?.name}</span>
                              </Box>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Box sx={{ display: "flex" }}>
                        {" "}
                        <TextField
                          id="demo-simple-search"
                          className={styles.custom_select}
                          sx={{
                            marginX: "1rem",
                            input: { color: "rgb(255, 255, 255)" },
                          }}
                          fullWidth
                          onChange={handleSearch}
                          label="Search"
                        />
                        <MDButton
                          variant="gradient"
                          color="success"
                          onClick={() => setShow(true)}
                        >
                          Create
                        </MDButton>
                      </Box>
                    </Grid>
                  </Grid>
                </MDBox>
                <MDBox pt={3}>
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                  {controller?.lessons.length === 0 && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingBottom: "1.5rem",
                      }}
                    >
                      <MDTypography
                        component="a"
                        variant="caption"
                        color="text"
                        fontWeight="medium"
                      >
                        Oops !! No Result Found
                      </MDTypography>
                    </Box>
                  )}
                </MDBox>
              </Card>
            </Grid>
            {(open || show) && (
              <Dialog open={show || open} onClose={handleClose}>
                <form onSubmit={handleSubmit(handlTitle)}>
                  <DialogTitle
                    id="alert-dialog-title"
                    sx={{ textAlign: "center" }}
                  >
                    {show ? "Add New Title" : open ? id?.name : ""}
                  </DialogTitle>
                  <DialogContent
                    maxWidth="md"
                    sx={{
                      minWidth: { xs: 300, sm: 400, md: 576 },
                    }}
                  >
                    <Grid item xs={12}>
                      <MDBox
                        display="grid"
                        p={{
                          xs: 1,
                          lg: 3,
                        }}
                        gap={2}
                      >
                        {show && (
                          <>
                            <TextField
                              label="Title"
                              fullWidth
                              {...register("title")}
                              helperText={errors.title?.message || ""}
                              error={Boolean(errors?.title)}
                              onBlur={() => trigger("title")}
                            />
                            <FormControl
                              variant="outlined"
                              fullWidth
                              error={Boolean(errors.lesson)}
                            >
                              <InputLabel
                                id="select-label"
                                sx={{
                                  backgroundColor: "#FFFFFF",
                                  padding: "0.2rem",
                                }}
                              >
                                Choose lesson
                              </InputLabel>
                              <Controller
                                name="lesson"
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    labelId="select-label"
                                    label="Lesson"
                                    {...field}
                                    onBlur={() => trigger("lesson")}
                                    sx={{ padding: "12px", paddingLeft: "0px" }}
                                    MenuProps={{
                                      PaperProps: {
                                        style: {
                                          maxHeight: "8rem",
                                        },
                                      },
                                    }}
                                  >
                                    {allLessons?.map((item) => {
                                      return (
                                        <MenuItem
                                          key={item?.id}
                                          value={item?.name}
                                        >
                                          {item?.name}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                )}
                              />
                              <FormHelperText
                                sx={{ color: "red" }}
                                id="outlined-weight-helper-text"
                              >
                                {errors.lesson?.message}
                              </FormHelperText>
                            </FormControl>
                            <FormControl
                              variant="outlined"
                              fullWidth
                              error={Boolean(errors.category)}
                            >
                              <InputLabel
                                id="select-label"
                                sx={{
                                  backgroundColor: "#FFFFFF",
                                  padding: "0.2rem",
                                }}
                              >
                                Choose category
                              </InputLabel>
                              <Controller
                                name="category"
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    labelId="select-label"
                                    label="Category"
                                    {...field}
                                    onBlur={() => trigger("category")}
                                    sx={{ padding: "12px", paddingLeft: "0px" }}
                                    MenuProps={{
                                      PaperProps: {
                                        style: {
                                          maxHeight: "8rem",
                                        },
                                      },
                                    }}
                                  >
                                    {categories?.map((item) => {
                                      return (
                                        <MenuItem
                                          key={item?.id}
                                          value={item?.id}
                                        >
                                          {item?.name}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                )}
                              />
                              <FormHelperText
                                sx={{ color: "red" }}
                                id="outlined-weight-helper-text"
                              >
                                {errors.category?.message}
                              </FormHelperText>
                            </FormControl>
                          </>
                        )}
                        {open && (
                          <>
                            <TextField
                              label="Title"
                              defaultValue={title}
                              fullWidth
                              {...register("title")}
                              helperText={errors.title?.message || ""}
                              error={Boolean(errors?.title)}
                              onBlur={() => trigger("title")}
                            />
                            <FormControl
                              variant="outlined"
                              fullWidth
                              error={Boolean(errors.category)}
                            >
                              <InputLabel
                                id="select-label"
                                sx={{
                                  backgroundColor: "#FFFFFF",
                                  padding: "0.2rem",
                                }}
                              >
                                Choose category
                              </InputLabel>
                              <Controller
                                name="category"
                                defaultValue={category?.id}
                                renderValue={() => category?.name}
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    labelId="select-label"
                                    label="Category"
                                    {...field}
                                    onChange={(event) => {
                                      field.onChange(event?.target?.value);
                                    }}
                                    onBlur={() => trigger("category")}
                                    sx={{ padding: "12px", paddingLeft: "0px" }}
                                    MenuProps={{
                                      PaperProps: {
                                        style: {
                                          maxHeight: "8rem",
                                        },
                                      },
                                    }}
                                  >
                                    {categories?.map((item) => {
                                      return (
                                        <MenuItem
                                          key={item?.id}
                                          value={item?.id}
                                        >
                                          {item?.name}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                )}
                              />
                              <FormHelperText
                                sx={{ color: "red" }}
                                id="outlined-weight-helper-text"
                              >
                                {errors.category?.message}
                              </FormHelperText>
                            </FormControl>
                          </>
                        )}
                      </MDBox>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <MDBox mt={3} mb={1}>
                      <MDButton
                        type="button"
                        variant="gradient"
                        color="secondary"
                        fullWidth
                        onClick={handleClose}
                      >
                        Cancel
                      </MDButton>
                    </MDBox>

                    <MDBox mt={3} mb={1}>
                      {show ? (
                        <MDButton
                          variant="gradient"
                          color="info"
                          fullWidth
                          disabled={isSubmiting}
                          type="submit"
                        >
                          {isSubmiting ? "creating..." : "create"}
                        </MDButton>
                      ) : (
                        <MDButton
                          variant="gradient"
                          color="info"
                          type="submit"
                          disabled={isSubmiting}
                          fullWidth
                        >
                          {isSubmiting ? "Changing..." : "Change"}
                        </MDButton>
                      )}
                    </MDBox>
                  </DialogActions>
                </form>
              </Dialog>
            )}
          </Grid>
          {controller?.totalItems > perPageContent && (
            <Pagination
              perPageContent={perPageContent}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
            />
          )}
        </MDBox>
      )}
    </ContentLayout>
  );
}

export default Lessons;
