import axios from "axios";
import Cookies from "js-cookie";
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Add request interceptor to set Authorization header
instance.interceptors.request.use(
  async (config) => {
    const token = Cookies.get("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Function to refresh the access token
const getNewToken = async () => {
  try {
    const refreshToken = Cookies.get("refreshToken");
    if (!refreshToken) {
      throw new Error("No refresh token found");
    }
    const access_token = Cookies.get("token");
    const response = await instance.post("/auth/token", {
      refresh_token: refreshToken,
      token: access_token,
    });
    const { token, refresh_token } = response?.data.data;
    if (token && refresh_token) {
      return { token, refresh_token };
    }
  } catch (error) {
    Cookies.remove("token");
    Cookies.remove("refreshToken");
    Cookies.remove("sessionId");
    Cookies.remove("email");
    throw new Error(error);
  }
};

// Add response interceptor for handling 401 errors and token refresh
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response.status === 401 &&
      !originalRequest._retry &&
      error?.request?.responseURL !==
        `${process.env.REACT_APP_API_URL}/auth/token`
    ) {
      if (
        error.request.responseURL ===
        `${process.env.REACT_APP_API_URL}/auth/local`
      ) {
        return Promise.reject(error);
      }
      originalRequest._retry = true;
      try {
        const expirationDate = new Date();
        expirationDate.setMonth(expirationDate.getMonth() + 2);
        const tokens = await getNewToken();
        Cookies.set("token", tokens?.token, {
          expires: expirationDate,
        });
        Cookies.set("refreshToken", tokens?.refresh_token, {
          expires: expirationDate,
        });
        instance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${tokens?.token}`;
        return instance(originalRequest);
      } catch (error) {
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);

export default instance;
