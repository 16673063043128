import { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
// import videojs from "video.js";
// import "video.js/dist/video-js.css";
import { setPlaying, useAdminPanelController } from "context";
import "./index.css";
const VideoPlayer = ({ videoUrl, id, video_thumbnail }) => {
  const playerRef = useRef(null);
  const [show, setShow] = useState(false);
  const [isPause, setIsPause] = useState(false);
  const [isMouse, setIsMouse] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [controller, dispatch] = useAdminPanelController();
  const [levels, setLevels] = useState([]);
  const divRef = useRef(null);
  const [currentQuality, setCurrentQuality] = useState(-1); // State to track current quality level
  const playerElement = divRef.current;
  const handlePlay = () => {
    setPlaying(dispatch, id);
    setIsPause(false);
    setIsMouse(false);
  };
  const getHls = () => {
    const player = playerRef.current;
    return player.getInternalPlayer("hls");
  };
  useEffect(() => {
    document.addEventListener("fullscreenchange", (event) => {
      setIsFullScreen((prev) => !prev);
      setShow(false);
    });
    return () => {
      document.removeEventListener("fullscreenchange", (event) => {
        setIsFullScreen((prev) => !prev);
        setShow(false);
      });
    };
  }, []);
  const onVideoReady = () => {
    if (/m3u8/.test(videoUrl.toLowerCase())) {
      const hls = getHls();
      hls.currentLevel = currentQuality;
      const levels = hls?.levels
        ?.map((level, index) => {
          return { level: level?.height, index: index };
        })
        .sort((a, b) => {
          return a.level - b.level;
        });
      setLevels(levels);
    }
  };

  const changeQuality = (index) => {
    const hls = getHls();
    hls.stopLoad();
    hls.currentLevel = index;
    hls.startLoad();
    setCurrentQuality(index);
    setShow(false);
  };
  const toggleFullScreen = () => {
    if (playerElement) {
      if (!isFullScreen) {
        if (playerElement.requestFullscreen) {
          playerElement.requestFullscreen();
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }
      }
    }
  };
  if (videoUrl === "") {
    return (
      <ReactPlayer
        className="react-player"
        url={
          "https://d398ed4titzii1.cloudfront.net/output-videos/17038309238.m3u8"
        }
        controls={true}
        width="100%"
        playing={controller?.isPlaying === id ? true : false}
        ref={playerRef}
        config={{
          file: {
            hlsOptions: {
              autoStartLoad: true,
              capLevelToPlayerSize: true,
              startLevel: -1,
              maxBufferLength: 10,
              backBufferLength: 45,
              frontBufferFlushThreshold: 30,
            },
          },
        }}
      />
    );
  }
  return (
    <div>
      <div className={isFullScreen ? "isFullScreen" : ""} ref={divRef}>
        <ReactPlayer
          className="react-player"
          url={videoUrl}
          controls={true}
          width="100%"
          height={isFullScreen ? "100%" : "auto"}
          light={<img src={video_thumbnail} width="100%" height="100%" />}
          playing={controller?.isPlaying === id ? true : false}
          ref={playerRef}
          config={{
            file: {
              hlsOptions: {
                autoStartLoad: true,
                capLevelToPlayerSize: true,
                startLevel: -1,
                maxBufferLength: 10,
                backBufferLength: 45,
                frontBufferFlushThreshold: 30,
              },
              attributes: { preload: "none", controlsList: "nodownload" },
            },
          }}
          onClickPreview={handlePlay}
          onPlay={handlePlay}
          onReady={onVideoReady}
          onPause={() => {
            setIsPause(true);
            setIsMouse(true);
          }}
          onMouseLeave={() => setShow(false)}
        />
        {(isPause || isMouse) && <div className="shadow"></div>}
        {(isPause || isMouse) && (
          <div className="icons">
            <div onMouseEnter={() => setShow(true)}>
              <img
                src="/settings.svg"
                alt=""
                className="icon_custom"
                height={isFullScreen ? 25 : 20}
                width={isFullScreen ? 25 : 20}
              />
            </div>

            {(isPause || isMouse) && (
              <div
                className={` ${isFullScreen && "full-screen"}`}
                onClick={toggleFullScreen}
              >
                <img
                  src={isFullScreen ? "/nofullscreen.png" : "/fullscreen.png"}
                  alt=""
                  className="icon_custom"
                  height={isFullScreen ? 25 : 16}
                  width={isFullScreen ? 25 : 16}
                />
              </div>
            )}
          </div>
        )}
        {show && (
          <div
            id="drp-list"
            className="quality"
            onMouseLeave={() => setShow(false)}
          >
            {levels?.map((level, index) => {
              return (
                <>
                  <button
                    onClick={() => changeQuality(index)}
                    className={`qualitybutton  ${
                      index === currentQuality
                        ? "text-yellow-500"
                        : "text-white"
                    } `}
                  >
                    {level.level}p
                  </button>
                </>
              );
            })}
            <button
              onClick={() => changeQuality(-1)}
              className={`qualitybutton ${
                currentQuality === -1 ? "text-yellow-500" : "text-white"
              } `}
            >
              Auto
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoPlayer;
