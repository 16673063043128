import { Modal } from "@mui/material";
import Form from "./form";

const EditVideo = ({ closeHandler, id }) => {
    return (
        <Modal
            open
            closeHandler={closeHandler}
            classes={{
                root: "video-form-modal",
            }}
        >
            <Form closeHandler={closeHandler} id={id} />
        </Modal>
    );
};

export default EditVideo;
