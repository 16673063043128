import { Box } from "@mui/material";
import FileImage from "assets/images/file.svg";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import { useDropzone } from "react-dropzone";
const UploadVideo = ({ onDrop, getRootProps, getInputProps }) => {
  // const { getRootProps, getInputProps,open } = useDropzone({
  //     onDrop,
  //     multiple: false,
  //     accept: "video/*", // Accept video files
  // });

  return (
    <Box
      {...getRootProps()}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <input {...getInputProps()} />
      <img src={FileImage} alt="file" />
      <MDTypography mt={2}>Drop your files here</MDTypography>
      <MDBox
        sx={{
          display: "flex",
          gap: "8px",
        }}
      >
        {" "}
        <MDTypography>or </MDTypography>
        <MDTypography
          color="success"
          sx={{
            fontWeight: 500,
            cursor: "pointer",
          }}
        >
          Browse
        </MDTypography>
      </MDBox>
    </Box>
  );
};

export default UploadVideo;
