import * as Yup from "yup";

export const lessonFormSchema = Yup.object({
  title: Yup.string()
    .trim()
    .required("Please enter lesson title")
    .min(3, "Title should be at least 3 characters")
    .max(40, "Title must be at most 40 characters"),
  lesson: Yup.string().required("Please select a lesson"),
  category: Yup.string().required("Please select a category"),
});

export const lessonEditSchema = Yup.object({
  title: Yup.string()
    .trim()
    .required("Please enter lesson title")
    .min(3, "Title should be at least 3 characters")
    .max(40, "Title must be at most 40 characters"),
  category: Yup.string().required("Please select a category"),
});
