import {
  Box,
  Card,
  FormControl,
  Grid,
  Icon,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import {
  setCategory,
  setLesson,
  setNotification,
  setSearch,
  setTotalItems,
  setVideos,
  useAdminPanelController,
} from "context";
import debounce from "lodash.debounce";
import { Fragment, useState } from "react";
import { getAllVideos } from "services/video";
import AddEditVideo from "./AddEditVideo";
const ActionBar = ({ lessons, setCurrentPage, categories }) => {
  const [controller, dispatch] = useAdminPanelController();
  const [addModal, setAddModal] = useState(false);
  const [render, setRender] = useState(false);
  const handleReset = () => {
    setRender((prev) => !prev);
    setCategory(dispatch, "");
    setLesson(dispatch, "");
    setSearch(dispatch, "");
  };
  const handleChange = (event, type) => {
    const {
      target: { value },
    } = event;
    if (type === "lesson") {
      setLesson(dispatch, value);
      setCategory(
        dispatch,
        typeof value == "number" ? controller.category : ""
      );
    } else {
      setCategory(dispatch, value);
      setLesson(dispatch, typeof value == "string" ? controller.lesson : "");
    }
  };
  const handleSearch = debounce(async (e) => {
    try {
      setSearch(dispatch, e.target.value.trim());
      const data = await getAllVideos(
        controller?.lesson,
        controller?.category,
        e.target.value.trim(),
        controller?.currentPage
      );
      if (data) {
        setVideos(dispatch, data?.videos);
        setTotalItems(dispatch, data?.count || null);
        setCurrentPage(1);
      } else {
        throw new Error("Something went wrong");
      }
    } catch (error) {
      setNotification(dispatch, {
        color: "error",
        icon: "error",
        title: "Error",
        content: error.message,
        open: true,
      });
    }
  }, 300);
  return (
    <Fragment>
      <MDBox
        py={3}
        sx={{
          marginRight: {
            xs: 0,
            sm: 5,
            lg: 5,
          },
          marginLeft: {
            xs: 0,
            sm: 5,
            lg: 5,
          },
        }}
      >
        <Card>
          <Grid
            container
            sx={{
              padding: "30px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <Grid
              item
              xs={12}
              lg={3}
              sx={{ display: "flex", alignItems: "center" }}
              className="input-group-left"
            >
              <Icon className="icon-left">event_note</Icon>
              <FormControl fullWidth>
                <Select
                  value={controller?.category}
                  onChange={(event) => handleChange(event, "category")}
                  sx={{
                    padding: "12px",
                  }}
                  displayEmpty
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  classes={{
                    select: "selected",
                  }}
                >
                  <MenuItem className="chapter-select" value="">
                    {" "}
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Icon
                        className="select-icon"
                        sx={{ marginRight: "10px" }}
                      >
                        style
                      </Icon>
                      <span> All Categories</span>
                    </Box>
                  </MenuItem>
                  {categories.map((category) => (
                    <MenuItem
                      key={category.id}
                      className="chapter-select"
                      value={category.id}
                    >
                      {" "}
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Icon
                          className="select-icon"
                          sx={{ marginRight: "10px" }}
                        >
                          style
                        </Icon>
                        <span> {category?.name}</span>
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              lg={3}
              sx={{ display: "flex", alignItems: "center" }}
              className="input-group-left"
            >
              <Icon className="icon-left">local_library</Icon>
              <FormControl fullWidth>
                <Select
                  value={controller?.lesson}
                  onChange={(event) => handleChange(event, "lesson")}
                  sx={{
                    padding: "12px",
                  }}
                  displayEmpty
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  MenuProps={{
                    style: {
                      maxHeight: "50vh",
                    },
                  }}
                  classes={{
                    select: "selected",
                  }}
                >
                  <MenuItem className="chapter-select" value="">
                    {" "}
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Icon
                        className="select-icon"
                        sx={{ marginRight: "10px" }}
                      >
                        style
                      </Icon>
                      <span> All Lessons</span>
                    </Box>
                  </MenuItem>
                  {lessons.map((lesson) => (
                    <MenuItem
                      key={lesson.id}
                      className="chapter-select"
                      value={lesson.id}
                    >
                      {" "}
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Icon
                          className="select-icon"
                          sx={{ marginRight: "10px" }}
                        >
                          style
                        </Icon>
                        <span> {lesson?.name}</span>
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              lg={3}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <FormControl fullWidth>
                <TextField
                  sx={{
                    padding: "12px",
                  }}
                  onChange={handleSearch}
                  placeholder="Search"
                  key={render}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              lg={2}
              sx={{ display: "flex", justifyContent: "space-around" }}
            >
              <MDButton
                variant="gradient"
                color="success"
                onClick={handleReset}
                sx={{
                  borderRadius: "25px",
                }}
              >
                Reset
              </MDButton>
              <MDButton
                variant="gradient"
                color="info"
                onClick={() => setAddModal(true)}
                sx={{
                  borderRadius: "25px",
                }}
              >
                Add Video
              </MDButton>
            </Grid>
          </Grid>
        </Card>
      </MDBox>
      {addModal && (
        <AddEditVideo
          closeHandler={() => {
            setAddModal(false);
          }}
        />
      )}
    </Fragment>
  );
};

export default ActionBar;
