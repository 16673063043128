import axios from "../util/axios";
export const getLessons = async (search, currentPage, category) => {
  try {
    const perPageContent = 10;
    const offset = (+currentPage - 1) * +perPageContent;
    const params = {
      offset,
      limit: perPageContent,
      populate: JSON.stringify(["category"]),
    };
    const whereCondition = {};
    if (category !== "") {
      whereCondition["$category.id$"] = category;
    }
    if (search !== "") {
      params.search = search;
    }
    params.where = JSON.stringify(whereCondition);
    const response = await axios.get("/lesson", { params });
    return response?.data?.data;
  } catch (error) {
    throw new Error(error?.response?.data?.error || "Something went wrong");
  }
};

export const putLesson = async (title, id, category_id) => {
  try {
    const response = await axios.put(`/lesson/${id}`, {
      title,
      category_id: Number(category_id),
    });
    return response?.status;
  } catch (error) {
    if (!navigator.onLine) {
      throw new Error("Slow or no internet connection");
    } else {
      throw new Error(error?.response?.data?.error || "Something went wrong");
    }
  }
};
