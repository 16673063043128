import { Modal } from "@mui/material";
import Form from "./form";

const AddEditVideo = ({ closeHandler }) => {
  return (
    <Modal
      open
      closeHandler={closeHandler}
      classes={{
        root: "video-form-modal",
      }}
    >
      <Form closeHandler={closeHandler} />
    </Modal>
  );
};

export default AddEditVideo;
