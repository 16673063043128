import axios from "../util/axios";

export const getCategories = async () => {
  try {
    const response = await axios.get("/category");
    return response?.data?.data?.categories;
  } catch (error) {
    throw new Error(error?.response?.data?.error || "Something went wrong");
  }
};
