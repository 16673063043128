import { Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDPagination from "components/MDPagination";
import { useAdminPanelController } from "context";

function Pagination({ perPageContent, currentPage, setCurrentPage }) {
  const [controller, dispatch] = useAdminPanelController();
  const totalPages = Math.ceil(controller?.totalItems / perPageContent);
  if (currentPage > totalPages) {
    setCurrentPage(totalPages);
  }
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  return (
    <MDBox py={3}>
      <MDPagination size="medium">
        <MDPagination
          item
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          <Icon>keyboard_arrow_left</Icon>
        </MDPagination>
        {currentPage !== 1 && (
          <MDPagination
            key={currentPage - 1}
            item
            active={false}
            onClick={() => handlePageChange(currentPage - 1)}
          >
            {currentPage - 1}
          </MDPagination>
        )}
        <MDPagination key={currentPage} item active={true}>
          {currentPage}
        </MDPagination>
        {currentPage !== totalPages && (
          <MDPagination
            key={currentPage + 1}
            item
            active={false}
            onClick={() => handlePageChange(currentPage + 1)}
          >
            {currentPage + 1}
          </MDPagination>
        )}
        <MDPagination
          item
          disabled={currentPage === totalPages}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          <Icon>keyboard_arrow_right</Icon>
        </MDPagination>
      </MDPagination>
    </MDBox>
  );
}

export default Pagination;
