import { Icon } from "@mui/material";
import Chapters from "pages/chapters";
import Dashboard from "pages/dashboard";
import Lessons from "pages/lesson";
import Settings from "pages/settings";
import SignIn from "pages/sign-in";
import Users from "pages/users";

const routes = [
  {
    type: "page",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/sign-in",
    component: <SignIn />,
  },
  {
    type: "collapse",
    name: "Dashboard",
    key: "",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Users",
    key: "users",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/users",
    component: <Users />,
  },
  {
    type: "collapse",
    name: "Lessons",
    key: "lessons",
    icon: <Icon fontSize="small">auto_stories</Icon>,
    route: "/lessons",
    component: <Lessons />,
  },
  {
    type: "collapse",
    name: "Videos",
    key: "videos",
    icon: <Icon fontSize="small">videocam</Icon>,
    route: "/videos",
    component: <Chapters />,
  },

  {
    type: "collapse",
    name: "Settings",
    key: "settings",
    icon: <Icon fontSize="small">settings</Icon>,
    route: "/settings",
    component: <Settings />,
  },
];

export default routes;
