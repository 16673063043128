import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Card,
  FormControl,
  FormHelperText,
  Grid,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-datetime/css/react-datetime.css";
// import { s3 } from "../../../util/s3Config";
import MDButton from "components/MDButton";
import {
  setNotification,
  setTotalItems,
  setVideos,
  useAdminPanelController,
} from "context";
import { useDropzone } from "react-dropzone";
import { Controller, useForm } from "react-hook-form";
import { videoFormSchema } from "schema/videoForm";
import {
  getAllVideos,
  postCompleteMultipart,
  postCreateMultipart,
  postVideo,
  putVideo,
  putVideoDetails,
} from "services/video";
import { uniqueName, validateVideo } from "util/helpers";
import UploadVideo from "./UploadVideo";
import styles from "./video.module.scss";

const Form = ({ closeHandler }) => {
  const [controller, dispatch] = useAdminPanelController();
  const [screen, setScreen] = useState(1);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [videoId, setVideoId] = useState(null);
  const [videoUrl, setVideoUrl] = useState("");
  const [category, setCategory] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    setValue,
    trigger,
    formState: { errors },
    setError,
    reset,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(videoFormSchema),
  });

  const handleLessonChagne = (value) => {
    const currentLesson = controller?.lessons.find(
      (item) => item?.name === value
    );
    if (!currentLesson?.category) {
      setCategory("");
      setMessage("The selected lesson has no category");
    } else {
      setMessage("");
      setCategory(currentLesson?.category?.name);
    }
  };

  const handleNewSection = async (data) => {
    setLoading(true);
    if (message) {
      return;
    }
    try {
      const { title, description, lesson, video_key } = data;
      const currentLesson = controller?.lessons.find(
        (item) => item?.name === lesson
      );
      const response = await putVideoDetails({
        title,
        description,
        lesson_id: currentLesson?.id,
        video_key,
        lesson_name: lesson,
      });
      if (response) {
        reset();
        const data = await getAllVideos(
          controller?.lesson,
          controller?.category,
          controller.search,
          controller?.currentPage
        );
        setVideos(dispatch, data?.videos);
        setTotalItems(dispatch, data?.count || null);
        setNotification(dispatch, {
          color: "success",
          icon: "check",
          title: "Success",
          content: "Video Added successfully",
          open: true,
        });
        setLoading(false);
        closeHandler();
      } else {
        throw new Error("Something went wrong");
      }
    } catch (error) {
      setNotification(dispatch, {
        color: "error",
        icon: "error",
        title: "Error",
        content: error.message,
        open: true,
      });
    }
  };
  const onDrop = async (acceptedFiles) => {
    setUploadProgress(0);
    if (acceptedFiles.length === 0) return;
    const isVideo = validateVideo(acceptedFiles[0]);
    if (isVideo !== "ok") {
      setError("video_key", {
        type: "manual",
        message: isVideo,
      });
      return;
    } else {
      console.log("herere");
      setError("video_key", {
        type: "manual",
        message: "",
      });
    }
    setScreen(2);
    const file = acceptedFiles[0];
    const fileName = uniqueName(file.name);
    try {
      let response;
      if (videoUrl !== "") {
        response = await putVideo(videoId, {
          video_key: fileName,
        });
        if (!response) {
          return;
        }
      } else {
        response = await postVideo(fileName);
        if (!response) {
          return;
        }
      }
      setValue("video_key", fileName);
      const partSize = 5 * 1024 * 1024; // 5MB per part (adjust as needed)
      const numParts = Math.ceil(file.size / partSize);
      const urlsResult = await postCreateMultipart(fileName, numParts);
      const uploadResults = [];
      let totalUploaded = 0;
      const { urls, uploadId } = urlsResult;
      for (let i = 0; i < urls.length; i++) {
        const partUrl = urls[i];
        const start = i * (1024 * 1024 * 5);
        const end = Math.min((i + 1) * (1024 * 1024 * 5), file.size);

        const part = file.slice(start, end);
        const response = await fetch(partUrl, {
          method: "PUT",
          body: part,
        });
        totalUploaded += part.size;
        const partData = {
          PartNumber: i + 1,
          ETag: response.headers.get("ETag"),
        };
        uploadResults.push(partData);
        const progress = Math.floor((totalUploaded / file.size) * 100);
        setUploadProgress(progress);
      }
      const res = await postCompleteMultipart(
        uploadId,
        fileName,
        uploadResults
      );
      setVideoUrl(response?.video_url);
      setVideoId(response?.id);
      setScreen(3);
    } catch (error) {
      setNotification(dispatch, {
        color: "error",
        icon: "error",
        title: "Error",
        content: error.message,
        open: true,
      });
    }
  };
  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    multiple: false,
    accept: "video/*", // Accept video files
  });

  return (
    <MDBox
      py={3}
      sx={{
        maxWidth: "1000px",
        width: "100%",
      }}
    >
      <Card className={styles.card}>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "30px",
            paddingBottom: "0px",
          }}
        >
          <MDBox
            sx={{
              display: "flex",
              gap: "5px",
            }}
          >
            <MDTypography variant="h4" color="success">
              ADD
            </MDTypography>

            <MDTypography variant="h4">VIDEO</MDTypography>
          </MDBox>
          <IconButton onClick={closeHandler}>
            <Icon>close</Icon>
          </IconButton>
        </Grid>
        <Grid
          container
          sx={{
            padding: "30px",
            paddingTop: "0px",
            gap: {
              xs: "20px",
              md: "0px",
            },
          }}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid
            item
            sx={{
              display: "grid",
            }}
            xs={12}
            alignItems={"center"}
            md={6}
          >
            <MDBox
              className={styles.add_video}
              sx={
                screen === 3
                  ? {
                      height: "max-content !important",
                    }
                  : screen === 1
                  ? {
                      cursor: "pointer",
                      border:
                        errors.video_key && errors.video_key !== ""
                          ? "1px dashed red"
                          : "2px dashed rgba(0, 0, 0, 0.2)",
                    }
                  : {}
              }
            >
              {screen === 1 && (
                <UploadVideo
                  onDrop={onDrop}
                  getRootProps={getRootProps}
                  getInputProps={getInputProps}
                />
              )}
              {screen === 2 && (
                <>
                  <MDBox
                    sx={{
                      maxWidth: "230px",
                    }}
                  >
                    {uploadProgress < 1 ? (
                      <Box className={styles.spinner}></Box>
                    ) : (
                      <CircularProgressbar
                        value={uploadProgress}
                        text={`${uploadProgress}%`}
                        strokeWidth={3}
                        styles={buildStyles({
                          pathColor: "#4caf50",
                          textColor: "rgba(0,0,0,0.4)",
                        })}
                      />
                    )}
                  </MDBox>
                </>
              )}
              {screen === 3 && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "18rem",
                  }}
                >
                  <MDTypography mt={2}>Uploaded</MDTypography>
                  <MDBox
                    sx={{
                      display: "flex",
                      gap: "8px",
                    }}
                  >
                    {" "}
                    <MDTypography>Successfully</MDTypography>
                  </MDBox>
                </Box>
              )}
            </MDBox>
            {screen === 3 && (
              <Grid xs={12} className={styles.video_actions} mt={2}>
                <MDBox
                  className={styles.edit}
                  onClick={() => {
                    // setError("video_key", {
                    //   type: "manual",
                    //   message: "",
                    // });
                    open();
                    // setScreen(1);
                    setUploadProgress(0);
                  }}
                >
                  {" "}
                  <Icon>edit</Icon>
                </MDBox>
              </Grid>
            )}
            {errors?.video_key && (
              <Typography
                variant="caption"
                sx={{
                  marginLeft: "1rem",
                  padding: "0.5rem",
                  color: "red",
                }}
              >
                {errors?.video_key?.message}
              </Typography>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <MDBox
              display="grid"
              p={{
                xs: 1,
                lg: 3,
              }}
            >
              <TextField
                label="Title"
                fullWidth
                sx={{ marginBottom: "1rem" }}
                {...register("title")}
                helperText={errors.title?.message || ""}
                error={Boolean(errors?.title)}
                onBlur={() => trigger("title")}
              />
              <TextField
                label="Description"
                {...register("description")}
                multiline
                sx={{ marginBottom: "1rem" }}
                rows={6}
                fullWidth
                helperText={errors.description?.message || ""}
                error={errors?.description ? true : false}
                onBlur={() => trigger("description")}
                InputProps={{
                  style: {
                    backgroundColor: "white",
                    paddingTop: "7px",
                  },
                }}
              />
              <FormControl
                variant="outlined"
                fullWidth
                error={Boolean(errors.lesson || message)}
              >
                <InputLabel id="select-label">Lesson</InputLabel>
                <Controller
                  name="lesson"
                  control={control}
                  render={({ field }) => (
                    <Select
                      labelId="select-label"
                      label="Lesson"
                      {...field}
                      onChange={(event) => {
                        field.onChange(event?.target?.value);
                        handleLessonChagne(event?.target?.value);
                      }}
                      onBlur={() => trigger("lesson")}
                      sx={{ padding: "12px" }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: "8rem",
                          },
                        },
                      }}
                    >
                      {controller?.lessons?.map((item) => {
                        return (
                          <MenuItem key={item?.id} value={item?.name}>
                            {item?.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                />

                <FormHelperText
                  sx={{
                    color: "red",
                  }}
                  id="outlined-weight-helper-text"
                >
                  {errors.lesson?.message || message}
                </FormHelperText>
              </FormControl>
              {category !== "" && (
                <span
                  style={{
                    fontSize: "0.9rem",
                    fontWeight: "400",
                    lineHeight: "1.4375em",
                    letterSpacing: " 0.00938em",
                    marginLeft: "1rem",
                    marginTop: "0.5rem",
                  }}
                >
                  {category}
                </span>
              )}

              <MDBox mt={3} mb={0}>
                <MDButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  disabled={loading}
                  onClick={handleSubmit(handleNewSection)}
                >
                  {loading ? "Submiting..." : "Submit"}
                </MDButton>
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>
      </Card>
    </MDBox>
  );
};

export default Form;
