import { Box } from "@mui/material";
import Card from "@mui/material/Card";
import Skeleton from "@mui/material/Skeleton";
import styles from "./skeletonvideocard.module.scss"; // You may need to create this file for styling

function SkeletonVideoCard() {
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "transparent",
        overflow: "visible",
        background: "rgba(0,0,0,0)",
        boxShadow: "none",
      }}
      className={styles.card}
    >
      <Box>
        <Skeleton
          variant="rectangular"
          height={120}
          sx={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}
        />{" "}
        {/* Adjust dimensions as needed */}
      </Box>

      <Box
        position="relative"
        width="100.25%"
        shadow="xl"
        borderRadius="xl"
        marginLeft="1rem"
      >
        <Skeleton variant="text" height={24} width={200} />
      </Box>

      <Box
        display="flex"
        gap={1}
        sx={{
          alignItems: "center",
        }}
      >
        <Skeleton variant="circular" width={20} height={20} />
        <Skeleton variant="text" height={16} width={40} />
      </Box>
      <Box className={styles.content}>
        <Box>
          <Skeleton variant="rectangular" height={12} width={100} />
        </Box>
        <Box>
          <Box lineHeight={0}>
            <Skeleton variant="text" height={12} />
          </Box>
          <Box lineHeight={0}>
            <Skeleton variant="text" height={12} />
          </Box>
          <Box mb={3} lineHeight={0}>
            <Skeleton variant="text" height={12} />
          </Box>
        </Box>
      </Box>
    </Card>
  );
}

SkeletonVideoCard.propTypes = {
  // You can define prop types if needed
};

export default SkeletonVideoCard;
