import Cookies from "js-cookie";
import axios from "../util/axios";
export const getSettingsInfo = async () => {
  try {
    const params = { where: JSON.stringify({ name: "subscription_amount" }) };
    const response = await axios.get("/setting/find", { params });
    return response?.data?.data?.setting;
  } catch (error) {
    throw new Error(error?.response?.data?.error || "Something went wrong");
  }
};
export const putSubscritpion = async (amount) => {
  try {
    const response = await axios.put(`/setting/subscription_amount`, {
      value: String(amount),
    });
    return response?.data?.data?.setting;
  } catch (error) {
    if (!navigator.onLine) {
      throw new Error("Slow or no internet connection");
    } else {
      throw new Error(error?.response?.data?.error || "Something went wrong");
    }
  }
};
export const getOtpToEmail = async (email) => {
  try {
    const response = await axios.put("/user/change_email", { email });
    return response?.data?.data?.session_id;
  } catch (error) {
    if (!navigator.onLine) {
      throw new Error("Slow or no internet connection");
    } else {
      if (error?.response?.data?.error === true) {
        throw new Error(
          error?.response?.data?.message || "Something went wrong"
        );
      } else {
        throw new Error(error?.response?.data?.error || "Something went wrong");
      }
    }
  }
};

export const resendOtp = async (session_id) => {
  try {
    const response = await axios.post("/auth/otp/send", {
      session_id,
    });

    return response?.data.message;
  } catch (error) {
    if (!navigator.onLine) {
      throw new Error("Slow or no Internet connection", "error");
    } else {
      if (typeof error?.response?.data?.error === "string") {
        throw new Error(error?.response?.data?.error || "Something went wrong");
      } else if (error?.response?.data?.message) {
        throw new Error(
          error?.response?.data?.message || "Something went wrong"
        );
      } else if (error?.message) {
        throw new Error(error?.message || "Something went wrong", "error");
      }
    }
  }
};
export const postOtp = async (otp, session_id) => {
  try {
    const response = await axios.post("/auth/otp/verify", {
      session_id,
      otp,
    });
    return response?.status;
  } catch (error) {
    if (!navigator.onLine) {
      throw new Error("Slow or no Internet connection", "error");
    } else {
      throw new Error(
        error?.response?.data?.message ||
          error?.message ||
          "something went wrong"
      );
    }
  }
};

export const updateNewEmail = async (email, session_id) => {
  try {
    const response = await axios.put("/user/update_email", {
      email,
      session_id,
    });
    if (response?.status === 200) {
      const expirationDate = new Date();
      expirationDate.setMonth(expirationDate.getMonth() + 2);
      Cookies.set("email", email, {
        expires: expirationDate,
      });
      return response?.status;
    }
  } catch (error) {
    if (!navigator.onLine) {
      throw new Error("Slow or no Internet connection", "error");
    } else {
      throw new Error(
        error?.response?.data?.message ||
          error?.message ||
          "something went wrong"
      );
    }
  }
};
