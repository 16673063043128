import { Icon, Menu, MenuItem } from "@mui/material";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Fragment, useState } from "react";
import { getDate } from "util/date";

// Images
const team =
  "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80";

export default function data(history) {
  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography
        display="block"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  const ActionItem = () => {
    const [menu, setMenu] = useState(null);

    const openMenu = ({ currentTarget }) => setMenu(currentTarget);
    const closeMenu = () => setMenu(null);

    const renderMenu = (
      <Menu
        id="simple-menu"
        anchorEl={menu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(menu)}
        onClose={closeMenu}
      >
        <MenuItem onClick={closeMenu}>Action</MenuItem>
        <MenuItem onClick={closeMenu}>Another action</MenuItem>
        <MenuItem onClick={closeMenu}>Something else</MenuItem>
      </Menu>
    );

    return (
      <Fragment>
        <MDBox color="text" px={2}>
          <Icon
            sx={{ cursor: "pointer", fontWeight: "bold" }}
            fontSize="small"
            onClick={openMenu}
          >
            more_vert
          </Icon>
        </MDBox>
        {renderMenu}
      </Fragment>
    );
  };

  return {
    columns: [
      { Header: "SL.NO", accessor: "number", align: "center" },
      {
        Header: "transaction ID",
        accessor: "transaction",
        align: "center",
      },
      { Header: "amount", accessor: "amount", align: "center" },
      {
        Header: "Subscription Date",
        accessor: "subscriptionDate",
        align: "center",
      },
      { Header: "Payment Method", accessor: "payment_method", align: "center" },
      { Header: "status", accessor: "status", align: "center" },
    ],

    rows: history.map((item, index) => {
      return {
        number: (
          <MDTypography
            component="a"
            variant="button"
            color="text"
            fontWeight="medium"
          >
            {index + 1}
          </MDTypography>
        ),
        transaction: (
          <MDTypography
            component="a"
            variant="button"
            color="text"
            fontWeight="medium"
          >
            {item?.transaction_id}
          </MDTypography>
        ),
        amount: (
          <MDTypography
            component="a"
            variant="button"
            color="text"
            fontWeight="medium"
          >
            {item?.amount} {item?.currency}
          </MDTypography>
        ),
        subscriptionDate: (
          <MDTypography
            component="a"
            variant="button"
            color="text"
            fontWeight="medium"
          >
            {item?.period_start
              ? getDate(item?.period_start.split("T")[0])
              : null}
          </MDTypography>
        ),
        payment_method: (
          <MDTypography
            component="a"
            variant="button"
            color="text"
            fontWeight="medium"
          >
            {item?.provider}
          </MDTypography>
        ),
        status: (
          <MDBox ml={-1}>
            <MDBadge
              badgeContent={
                item?.payment_status ? item?.payment_status : "Pending"
              }
              color={
                item?.payment_status === "COMPLETED"
                  ? "success"
                  : item?.payment_status === "FAILED"
                  ? "error"
                  : "warning"
              }
              variant="gradient"
              size="sm"
            />
          </MDBox>
        ),
      };
    }),
  };
}
