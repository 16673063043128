import axios from "../util/axios";

export const getCounts = async () => {
  try {
    const response = await axios.get("/user/admin_dashboard_count");
    return response?.data?.data?.dashboard;
  } catch (error) {
    throw new Error(error?.response?.data?.error || "Something went wrong");
  }
};

export const getBarStatistics = async ({ from, to }) => {
  try {
    const response = await axios.get("/user/admin_dashboard/registrations", {
      params: {
        where: JSON.stringify({
          from,
          to,
        }),
      },
    });

    const { registered_users } = response?.data?.data;
    const barChartData = {
      labels: registered_users[0],
      datasets: {
        label: "Registered users",
        data: registered_users[1],
      },
    };
    return { barChartData };
  } catch (error) {
    throw new Error(error?.response?.data?.error || "Something went wrong");
  }
};

export const getLineStatistics = async ({ from, to }) => {
  try {
    const response = await axios.get("/user/admin_dashboard/subscriptions", {
      params: {
        where: JSON.stringify({
          from,
          to,
        }),
      },
    });

    const { subscribed_users } = response?.data?.data;

    const lineChartData = {
      labels: subscribed_users[0],
      datasets: {
        label: "Subscribed users",
        data: subscribed_users[1],
      },
    };

    return { lineChartData };
  } catch (error) {
    throw new Error(error?.response?.data?.error || "Something went wrong");
  }
};
