import { useRef } from "react";
import ReactPlayer from "react-player";
const VideoPlayer = ({ videoUrl, id }) => {
  const playerRef = useRef(null);
  const getHls = () => {
    const player = playerRef.current;
    return player.getInternalPlayer("hls");
  };
  const onVideoReady = () => {
    if (/m3u8/.test(videoUrl.toLowerCase())) {
      const hls = getHls();
      hls.currentLevel = -1;
    }
  };
  return (
    <ReactPlayer
      // className="react-player"
      url={videoUrl}
      controls={true}
      width="100%"
      height="16.5rem"
      playing={false}
      ref={playerRef}
      config={{
        file: {
          hlsOptions: {
            autoStartLoad: true,
            capLevelToPlayerSize: true,
            startLevel: -1,
            maxBufferLength: 10,
            backBufferLength: 45,
            frontBufferFlushThreshold: 30,
          },
        },
      }}
      onReady={onVideoReady}
    />
  );
};

export default VideoPlayer;
