import { subDays } from "date-fns";
import format from "date-fns/format";
import { useEffect, useRef, useState } from "react";
import { DateRange } from "react-date-range";
import "./index.css";

import { TextField } from "@mui/material";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { getGraphDate } from "util/date";

const DateRangeComp = ({ onChange }) => {
  // date state
  const [range, setRange] = useState([
    {
      startDate: subDays(new Date(), 7),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  // open close
  const [open, setOpen] = useState(false);

  // get the target element to toggle
  const refOne = useRef(null);

  useEffect(() => {
    // event listeners
    document.addEventListener("keydown", hideOnEscape, true);
    document.addEventListener("click", hideOnClickOutside, true);
  }, []);

  const hideOnEscape = (e) => {
    if (e.key === "Escape") {
      setOpen(false);
    }
  };

  const hideOnClickOutside = (e) => {
    if (refOne.current && !refOne.current.contains(e.target)) {
      setOpen(false);
    }
  };

  const handleChange = (item) => {
    onChange({
      from: getGraphDate(item.selection?.startDate),
      to: getGraphDate(item.selection?.endDate),
    });
    setRange([item.selection]);
    // setOpen(false);
  };
  return (
    <div className="calendarWrap">
      <TextField
        sx={{ width: "13rem" }}
        value={`${format(range[0].startDate, "MM/dd/yyyy")} to ${format(
          range[0].endDate,
          "MM/dd/yyyy"
        )}`}
        readOnly
        className="inputBox"
        onClick={() => setOpen((open) => !open)}
      />

      <div ref={refOne}>
        {open && (
          <DateRange
            onChange={handleChange}
            editableDateInputs={false}
            moveRangeOnFirstSelection={false}
            ranges={range}
            direction="horizontal"
            className="calendarElement"
            preventSnapRefocus={true}
            calendarFocus="backwards"
          />
        )}
      </div>
    </div>
  );
};

export default DateRangeComp;
