import Navbar from "components/Navbar";
import ContentLayout from "layouts/content";

import ComplexStatisticsCard from "components/Cards/StatisticsCards/ComplexStatisticsCard";
import ReportsLineChart from "components/Charts/LineCharts/ReportsLineChart";

// components
import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import {
  getBarStatistics,
  getCounts,
  getLineStatistics,
} from "services/dashboard";
import { getDateRange } from "util/helpers";

const Dashboard = () => {
  const [counts, setCounts] = useState({});
  const [barDataSet, setBarDataSet] = useState({});
  const [lineDataSet, setLineDataSet] = useState({});
  const [barDateRange, setDateRange] = useState(getDateRange());
  const [lineDateRange, setLineDateRange] = useState(getDateRange());
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getCounts();
        setCounts(response);
      } catch (error) {}
    };
    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { lineChartData } = await getLineStatistics(lineDateRange);
        setLineDataSet(lineChartData);
      } catch (error) {}
    };
    fetchData();
  }, [lineDateRange]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { barChartData } = await getBarStatistics(barDateRange);
        setBarDataSet(barChartData);
      } catch (error) {}
    };
    fetchData();
  }, [barDateRange]);
  return (
    <ContentLayout>
      <Navbar />
      <MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="group"
                title="Total Registered Users"
                count={counts?.registered_users}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="group"
                title="Total Subscribed Users"
                count={counts?.subscribed_users}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="local_library"
                title="Total Lessons"
                count={counts?.total_lessons}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="videocam"
                title="Total Videos"
                count={counts?.total_videos}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="Registered users"
                  chart={barDataSet}
                  onChange={setDateRange}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="Subscribed users"
                  chart={lineDataSet}
                  onChange={setLineDateRange}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </ContentLayout>
  );
};

export default Dashboard;
