import { yupResolver } from "@hookform/resolvers/yup";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import LogoImage from "assets/images/logo.png";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { setNotification, useAdminPanelController } from "context";
import PageLayout from "layouts/page";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { loginFormSchema } from "schema/loginForm";
import { postLogin } from "services/auth";
function SignIn() {
  const [showPassword, setShowPassword] = useState(false);
  const [controller, dispatch] = useAdminPanelController();
  const [loading, setLoading] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(loginFormSchema),
  });
  const handleLogin = async (data) => {
    setLoading(true);
    try {
      const response = await postLogin(data);
      if (response?.message) {
        reset();
        setNotification(dispatch, {
          color: "success",
          icon: "check",
          title: "Success",
          content: "You have successfully logged in",
          open: true,
        });
        navigate("/", { replace: true });
      }
    } catch (error) {
      setNotification(dispatch, {
        color: "error",
        icon: "error",
        title: "Error",
        content: error.message,
        open: true,
      });
    }
    setLoading(false);
  };

  return (
    <PageLayout>
      <MDBox px={1} width="100%" height="100vh" mx="auto">
        <Grid
          container
          spacing={1}
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <Card>
              <MDBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <img
                  src={LogoImage}
                  style={{ width: "30%", height: "30%" }}
                  alt="file"
                />
                <MDTypography
                  variant="h4"
                  fontWeight="medium"
                  color="white"
                  mt={1}
                >
                  Sign in
                </MDTypography>
              </MDBox>
              <MDBox pt={4} pb={3} px={3}>
                <MDBox component="form" role="form">
                  <form onSubmit={handleSubmit(handleLogin)}>
                    <MDBox mb={2}>
                      <TextField
                        type="email"
                        label="Email"
                        {...register("username")}
                        fullWidth
                        helperText={errors.username?.message || ""}
                        error={Boolean(errors.username)}
                      />
                    </MDBox>
                    <MDBox mb={2}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel
                          htmlFor="outlined-adornment-password "
                          error={Boolean(errors.password)}
                          id="input_categoryselected"
                          style={{
                            padding: "0.2rem",
                          }}
                        >
                          Password
                        </InputLabel>
                        <OutlinedInput
                          error={Boolean(errors.password)}
                          {...register("password")}
                          type={showPassword ? "text" : "password"}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          label="Password"
                        />
                        <FormHelperText
                          sx={{ color: "red" }}
                          id="outlined-weight-helper-text"
                        >
                          {errors.password?.message || ""}
                        </FormHelperText>
                      </FormControl>
                    </MDBox>

                    <MDBox mt={4} mb={1}>
                      <MDButton
                        type="submit"
                        variant="gradient"
                        disabled={loading}
                        color="info"
                        fullWidth
                        onClick={handleSubmit(handleLogin)}
                      >
                        sign in
                      </MDButton>
                    </MDBox>
                  </form>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </PageLayout>
  );
}

export default SignIn;
