import Cookies from "js-cookie";
import { Navigate, Outlet } from "react-router";

const checkToken = () => {
  const token = Cookies.get("token");
  return token;
};
export const Protected = () => {
  const isAuth = checkToken();
  return isAuth ? <Outlet /> : <Navigate to="/sign-in" />;
};

export const CheckLogin = () => {
  const isAuth = checkToken();
  return isAuth ? <Navigate to="/" /> : <Outlet />;
};
