import Navbar from "components/Navbar";
import Pagination from "components/Pagination";
import {
  setLessons,
  setTotalItems,
  setVideos,
  useAdminPanelController,
} from "context";
import ContentLayout from "layouts/content";
import { useEffect, useState } from "react";
import { getCategories } from "services/category";
import { getAllVideos, getLessons } from "services/video";
import ActionBar from "./actionbar";
import Videos from "./videos";

const Chapters = () => {
  const [loading, setLoading] = useState(true);
  const [controller, dispatch] = useAdminPanelController();
  const [currentPage, setCurrentPage] = useState(1);
  const [categories, setCategories] = useState([]);
  const perPageContent = process.env.REACT_APP_PER_PAGE_CONTENT || 12;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getLessons(controller?.category);
        const categoryData = await getCategories();
        setLessons(dispatch, data);
        setCategories(categoryData);
      } catch (error) {}
    };
    fetchData();
  }, [controller?.category]);
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response = await getAllVideos(
          controller?.lesson,
          controller?.category,
          controller?.search,
          currentPage
        );
        setLoading(false);
        setVideos(dispatch, response?.videos);
        setTotalItems(dispatch, response?.count || null);
      } catch (error) {
        setLoading(false);
      }
    };
    fetchData();
  }, [
    controller?.lesson,
    currentPage,
    controller?.category,
    controller?.search,
  ]);
  return (
    <ContentLayout>
      <Navbar />
      <ActionBar
        lessons={controller.lessons}
        setCurrentPage={setCurrentPage}
        categories={categories}
      />
      <Videos
        items={controller.videos}
        loading={loading}
        currentPage={currentPage}
      />
      {controller?.totalItems >= perPageContent && (
        <Pagination
          perPageContent={perPageContent}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
      )}
    </ContentLayout>
  );
};

export default Chapters;
