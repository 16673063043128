import * as Yup from "yup";

export const videoFormSchema = Yup.object({
    video_key: Yup.string().required("Please upload the video "),
    title: Yup.string()
        .trim()
        .required("Please enter video title")
        .min(3, "Title should be at least 3 characters")
        .max(50, "Title must be at most 50 characters"),
    description: Yup.string()
        .trim()
        .required("Please provide a description")
        .min(3, "Description should be at least 3 characters")
        .max(500, "Description must be at most 500 characters"),
    lesson: Yup.string().required("Please select a lesson"),
});
