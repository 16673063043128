import { createContext, useContext, useMemo, useReducer } from "react";

import PropTypes from "prop-types";

const AdminPanel = createContext();
AdminPanel.displayName = "AdminPanelContext";

function reducer(state, action) {
  switch (action.type) {
    case "MINI_SIDENAV": {
      return { ...state, miniSidenav: action.value };
    }
    case "TRANSPARENT_NAVBAR": {
      return { ...state, transparentNavbar: action.value };
    }
    case "FIXED_NAVBAR": {
      return { ...state, fixedNavbar: action.value };
    }
    case "DARKMODE": {
      return { ...state, darkMode: action.value };
    }
    case "LAYOUT": {
      return { ...state, layout: action.value };
    }
    case "LESSON": {
      return { ...state, lesson: action.value };
    }
    case "VIDEO": {
      return { ...state, videoForEdit: action.value };
    }
    case "VIDEOS": {
      return { ...state, videos: action.value };
    }
    case "LESSONS": {
      return { ...state, lessons: action.value };
    }
    case "SERACH": {
      return { ...state, search: action.value };
    }
    case "NOTIFICATION": {
      return { ...state, notification: action.value };
    }
    case "TOTALITEMS": {
      return { ...state, totalItems: action.value };
    }
    case "CURRENTPAGE": {
      return { ...state, currentPage: action.value };
    }
    case "PLAY": {
      return { ...state, isPlaying: action.value };
    }

    case "CATEGORY": {
      return { ...state, category: action.value };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function AdminPanelControllerProvider({ children }) {
  const initialState = {
    miniSidenav: false,
    sidenavColor: "info",
    fixedNavbar: true,
    darkMode: false,
    lesson: "",
    category: "",
    videoForEdit: null,
    videos: [],
    lessons: [],
    isPlaying: null,
    loading: false,
    search: "",
    totalItems: null,
    currentPage: 1,
    notification: {
      color: "success",
      icon: "",
      title: "",
      content: "",
      open: false,
    },
  };

  const [controller, dispatch] = useReducer(reducer, initialState);
  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

  return <AdminPanel.Provider value={value}>{children}</AdminPanel.Provider>;
}

function useAdminPanelController() {
  const context = useContext(AdminPanel);

  if (!context) {
    throw new Error(
      "useAdminPanelController should be used inside the AdminPanelControllerProvider."
    );
  }

  return context;
}

// Typechecking props for the MaterialUIControllerProvider
AdminPanelControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context module functions
const setMiniSidenav = (dispatch, value) =>
  dispatch({ type: "MINI_SIDENAV", value });
const setSidenavColor = (dispatch, value) =>
  dispatch({ type: "SIDENAV_COLOR", value });
const setFixedNavbar = (dispatch, value) =>
  dispatch({ type: "FIXED_NAVBAR", value });
const setTransparentNavbar = (dispatch, value) =>
  dispatch({ type: "TRANSPARENT_NAVBAR", value });
const setDarkMode = (dispatch, value) => dispatch({ type: "DARKMODE", value });
const setLayout = (dispatch, value) => dispatch({ type: "LAYOUT", value });
const setLesson = (dispatch, value) => dispatch({ type: "LESSON", value });
const setCategory = (dispatch, value) => dispatch({ type: "CATEGORY", value });
const setVideo = (dispatch, value) => dispatch({ type: "VIDEO", value });
const setVideos = (dispatch, value) => dispatch({ type: "VIDEOS", value });
const setLessons = (dispatch, value) => dispatch({ type: "LESSONS", value });
const setSearch = (dispatch, value) => dispatch({ type: "SERACH", value });
const setPlaying = (dispatch, value) => dispatch({ type: "PLAY", value });
const setCurrentPage = (dispatch, value) =>
  dispatch({ type: "CURRENTPAGE", value });
const setNotification = (dispatch, value) =>
  dispatch({ type: "NOTIFICATION", value });
const setTotalItems = (dispatch, value) =>
  dispatch({ type: "TOTALITEMS", value });

export {
  AdminPanelControllerProvider,
  setCategory,
  setCurrentPage,
  setDarkMode,
  setFixedNavbar,
  setLayout,
  setLesson,
  setLessons,
  setMiniSidenav,
  setNotification,
  setPlaying,
  setSearch,
  setSidenavColor,
  setTotalItems,
  setTransparentNavbar,
  setVideo,
  setVideos,
  useAdminPanelController,
};
