import * as Yup from "yup";

export const amountSchema = Yup.object({
  amount: Yup.number("Amount must be a number")
    .required("Please enter the amount")
    .typeError("Please enter  the amount")
    .min(1, "Amount must be positive"),
});

export const passwordSchema = Yup.object({
  currentPassword: Yup.string()
    .trim()
    .required("Please enter  current password")
    .min(6, "Password must be at least 6 characters long"),
  newPassword: Yup.string()
    .trim()
    .required("Please enter new password")
    .min(6, "Password must be at least 6 characters long"),
  confirmPassword: Yup.string()
    .required("Please conform your password")
    .oneOf([Yup.ref("newPassword")], "Password must match"),
});

export const emailShcema = Yup.object({
  email: Yup.string()
    .trim()
    .email("Email must be a valid email")
    .required("Please enter your email")
    .test("after-at", "Email must be a valid email", (value) => {
      const parts = value.split("@");
      if (parts.length === 2) {
        const [localPart, domainPart] = parts;
        return (
          domainPart.includes(".") &&
          domainPart.indexOf(".") === domainPart.lastIndexOf(".") &&
          domainPart.length > 1 &&
          localPart.length > 0
        );
      }
      return false;
    }),
});
export const optSchema = Yup.object({
  otp: Yup.string(),
});
