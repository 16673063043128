import { Box, Icon } from "@mui/material";
import MDTypography from "components/MDTypography";
export default function data(
  setOpen,
  lessons,
  setId,
  setTitle,
  setCategory,
  page
) {
  return {
    columns: [
      { Header: "SL.NO", accessor: "number", align: "center" },
      { Header: "name", accessor: "name", align: "left" },
      { Header: "title", accessor: "title", align: "left" },
      { Header: "category", accessor: "category", align: "left" },
      { Header: "action", accessor: "action", align: "center" },
    ],

    rows: lessons.map((lesson, index) => {
      return {
        number: (
          <MDTypography
            component="a"
            variant="button"
            color="text"
            fontWeight="medium"
          >
            {(page - 1) * 10 + (index + 1)}
          </MDTypography>
        ),
        name: (
          <MDTypography
            component="a"
            variant="button"
            color="text"
            fontWeight="medium"
          >
            {lesson?.name}
          </MDTypography>
        ),
        category: (
          <MDTypography
            component="a"
            variant="button"
            color="text"
            fontWeight="medium"
          >
            {lesson?.category?.name ?? "NA"}
          </MDTypography>
        ),
        title: (
          <MDTypography
            component="a"
            variant="button"
            color="text"
            fontWeight="medium"
          >
            {lesson?.title ?? "NA"}
          </MDTypography>
        ),
        action: (
          <Box
            sx={{
              border: "1px solid #dddddd",
              borderRadius: "1.2rem",
              paddingTop: "0.4rem",
              width: "2rem",
              height: "2rem",
              cursor: lesson?.title ? "pointer" : "default",
              backgroundColor: lesson?.title ? "white" : "#dddddd",
            }}
            onClick={() => {
              if (lesson?.title) {
                setId({ id: lesson?.id, name: lesson?.name });
                setTitle(lesson?.title);
                setCategory(lesson?.category);
                setOpen(true);
              } else {
                return;
              }
            }}
          >
            <Icon>edit</Icon>
          </Box>
        ),
      };
    }),
  };
}
