import axios from "../util/axios";

export const getLessons = async (category) => {
  try {
    const whereCondition = {};
    if (category !== "") {
      whereCondition["$category.id$"] = category;
    }
    const response = await axios.get("/lesson", {
      params: {
        populate: JSON.stringify(["category"]),
        where: JSON.stringify(whereCondition),
        limit: -1,
      },
    });
    return response?.data?.data?.lessons;
  } catch (error) {
    throw new Error(error?.response?.data?.error || "Something went wrong");
  }
};

export const postVideo = async (video_key) => {
  try {
    const response = await axios.post("/video", { video_key });
    return response?.data.data.video;
  } catch (error) {
    if (!navigator.onLine) {
      throw new Error("Slow or no internet connection");
    } else {
      throw new Error(error?.response?.data?.error || "Something went wrong");
    }
  }
};

export const postCreateMultipart = async (fileName, parts) => {
  try {
    const response = await axios.post("/user/create_multipart", {
      fileName,
      parts: Number(parts),
    });
    return response?.data?.data?.urls;
  } catch (error) {
    if (!navigator.onLine) {
      throw new Error("Slow or no internet connection");
    } else {
      throw new Error(error?.response?.data?.error || "Something went wrong");
    }
  }
};

export const postCompleteMultipart = async (uploadId, fileName, parts) => {
  try {
    const response = await axios.post("/user/complete_mulitpart", {
      uploadId,
      fileName,
      parts: JSON.stringify(parts),
    });
    return response?.data?.data?.urls;
  } catch (error) {
    if (!navigator.onLine) {
      throw new Error("Slow or no internet connection");
    } else {
      throw new Error(error?.response?.data?.error || "Something went wrong");
    }
  }
};

export const putVideoDetails = async (data) => {
  try {
    const response = await axios.put("/video", data);

    return response?.data?.data?.setting;
  } catch (error) {
    if (!navigator.onLine) {
      throw new Error("Slow or no internet connection");
    } else {
      throw new Error(error?.response?.data?.error || "Something went wrong");
    }
  }
};

export const putVideo = async (id, data) => {
  try {
    const response = await axios.put(`/video/${id}`, data);
    return response?.data?.data?.video;
  } catch (error) {
    if (!navigator.onLine) {
      throw new Error("Slow or no internet connection");
    } else {
      throw new Error(error?.response?.data?.error || "Something went wrong");
    }
  }
};

export const getAllVideos = async (lesson, category, search, currentPage) => {
  try {
    const perPageContent = process.env.REACT_APP_PER_PAGE_CONTENT || 12;
    const offset = (+currentPage - 1) * +perPageContent;
    const whereCondition = {
      title: { $ne: null },
    };
    if (category !== "") {
      whereCondition["$lesson.category_id$"] = category;
    }
    if (lesson !== "") {
      whereCondition.lesson_id = lesson;
    }
    const params = {
      sort: JSON.stringify([["created_at", "desc"]]),
      offset,
      limit: perPageContent,
      where: JSON.stringify(whereCondition),
      populate: JSON.stringify(["lesson", "lesson.category"]),
    };
    if (search !== "") {
      params.search = search;
    }
    const response = await axios.get("/video", {
      params,
    });
    return response?.data?.data;
  } catch (error) {
    throw new Error(error?.response?.data?.error || "Something went wrong");
  }
};
export const getVideo = async (id) => {
  try {
    const response = await axios.get(`/video/${id}`);

    return response?.data?.data?.video;
  } catch (error) {
    throw new Error(error?.response?.data?.error || "Something went wrong");
  }
};

export const deleteVideo = async (id) => {
  try {
    const response = await axios.delete(`/video/${id}`);
    return response?.data;
  } catch (error) {
    if (!navigator.onLine) {
      throw new Error("Slow or no internet connection");
    } else {
      throw new Error(error?.response?.data?.error || "Something went wrong");
    }
  }
};
